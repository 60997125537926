import { FunctionComponent } from 'react';
import RiveAnimation from '@rive-app/react-webgl2';
import { get404Destinations } from '../lib/destinations';
import LayoutV2 from 'components/common/LayoutV2';

import styles from 'styles/404.module.css';

const FourOhFour: FunctionComponent<{}> = () => {
  const destinations = get404Destinations();
  return (
    <LayoutV2 title="404" hideMenu>
      <div className={styles.FourOhFour}>
        <div className={styles.AnimationContainer}>
          <RiveAnimation src={'/static/riv/404v2.riv'} />
        </div>
        <img
          className={styles.HeaderLogo}
          alt="Rive logo"
          src="/static/rive_logo_white.svg"
        />
        <div className={styles.Divider} />
        <div className={styles.MessageContainer}>
          <div className={styles.Message}>
            <h1>Oops!</h1>
            <h2>We couldn't find the page you were looking for</h2>
            <p className={styles.Prompt}>Here are some alternatives! </p>
          </div>
          <div className={styles.Destinations}>
            {destinations.map((item) => {
              return (
                <a href={item.href} key={item.title}>
                  <div key={item.title} className={styles.Destination}>
                    <img
                      className={styles.Thumbnail}
                      alt={item.img_alt}
                      src={item.desktop_img}
                    />
                    <div>
                      <h3>{item.title}</h3>
                      <p>{item.caption}</p>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </LayoutV2>
  );
};

export default FourOhFour;
