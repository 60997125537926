import data from '../data/destinations.json';

type Destination = {
  title: string;
  caption: string;
  desktop_img: string;
  mobile_img: string | null;
  img_alt: string;
  href: string;
};

export function get404Destinations() {
  return data.menu as Destination[];
}
